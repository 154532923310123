// 提成模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/';

export default {
  /**
   * @description: 提成管理搜索
   * @return {void}
   */
  'POST/commission/manage': (params: any, extra?: Extra) => {
    return axios(`/commission/manage`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建提成管理
   * @return {void}
   */
  'POST/commission/manage/create': (params: any, extra?: Extra) => {
    return axios(`/commission/manage/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情查询
   * @param {number} manageId 提成管理ID
   * @return {void}
   */
  'POST/commission/manage/detail': (params: {manageId: number}, extra?: Extra) => {
    return axios(`/commission/manage/detail`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情添加其他明细
   * @return {void}
   */
  'POST/commission/manage/detail/create': (params: any, extra?: Extra) => {
    return axios(`/commission/manage/detail/create`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提成管理详情推送
   * @param {number} id
   * @return {void}
   */
  'PUT/commission/manage/confirm/{id}': (params: {id: number}, extra?: Extra) => {
    return axios(`/commission/manage/confirm/${params.id}`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 提成管理详情推送前校验
   * @param {number} id
   * @return {void}
   */
  'PUT/commission/manage/validate/{id}': (params: {id: number}, extra?: Extra) => {
    return axios(`/commission/manage/validate/${params.id}`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 删除提成管理
   * @param {number} id
   * @return {void}
   */
  'DELETE/commission/manage/{id}': (params: {id: number}) => {
    return axios.delete<void>(`/commission/manage/${params.id}`)
  },
  
  /**
   * @description: 订单提成导出
   * @return {void}
   */
  'POST/commission/export': (params: any, extra?: Extra) => {
    return axios(`/commission/export`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成管理
   * @param {array} detailIds
   * @return {void}
   */
  'POST/commission/manage/detail/del': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/commission/manage/detail/del`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 发放清单-生成凭证
   * @param {array} detailIds
   * @return {void}
   */
  'POST/salaries/distributes/generate_voucher': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/salaries/distributes/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 社保缴费明细-上传记录-生成凭证
   * @param {array} detailIds
   * @return {void}
   */
  'POST/social_security_bureau_datas/generate_voucher': (params: {detailIds: number[]}, extra: Extra) => {
    return axios(`/social_security_bureau_datas/generate_voucher`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除校验出的错误数据
   * @return {void}
   */
  'POST/commission/manage/validate/del': (params: any, extra: Extra) => {
    return axios(`/commission/manage/validate/del`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成基础
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/page': (params: any, extra: Extra) => {
    return axios(`/commission/base/page`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成基础导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/export': (params: any, extra: Extra) => {
    return axios(`/commission/base/export`, preParams(params, extra, 'post'))
  },
    
  /**
   * @description: 提成基础明细导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/base/export_detail': (params: any, extra: Extra) => {
    return axios(`/commission/base/export_detail`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 生成提成数据
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/init_base': (params: any, extra: Extra) => {
    return axios(`/commission/init_base`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成数据
   * @param {string} initFrom
   * @param {string} initTo
   * @return {void}
   */
  'POST/commission/init_base/del': (params: {initFrom: string, initTo: string}, extra: Extra) => {
    return axios(`/commission/init_base/del`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 导入差异金额
   * @return {void}
   */
  'POST/commission/import/diff_amount': (params: any, extra: Extra) => {
    return axios(`/commission/import/diff_amount`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 下载差异金额导入模板
   * @return {void}
   */
  'POST/commission/download/template/diff_amount': (params: any, extra: Extra) => {
    return axios(`/commission/download/template/diff_amount`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 修改差异金额
   * @param {number} id
   * @param {number} diffAmount
   * @return {void}
   */
  'POST/commission/update/diff_amount': (params: {id: number, diffAmount: number}, extra: Extra) => {
    return axios(`/commission/update/diff_amount`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成率
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/ratio/page': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/page`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 提成率导出
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/ratio/export': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/export`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 导入提成项目
   * @param {file} file
   * @return {void}
   */
  'POST/commission/import/ratio': (params: any, extra: Extra) => {
    return axios(`/commission/import/ratio`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 下载导入提成模板
   * @return {void}
   */
  'POST/commission/download/template/ratio': (params: any, extra: Extra) => {
    return axios(`/commission/download/template/ratio`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 新增提成项目
   * @return {void}
   */
  'POST/commission/ratio/add': (params: any, extra: Extra) => {
    return axios(`/commission/ratio/add`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 删除提成项目
   * @param {array} ids
   * @return {void}
   */
  'DELETE/commission/ratio/del': (params: {ids: number[]}, extra: Extra) => {
    return axios.delete<void>(`/commission/ratio/del`, preParams(params, extra, 'delete'))
  },

  /**
   * @description: 提成汇总
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/users': (params: any, extra: Extra) => {
    return axios(`/commission/users`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 获取提成项目
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'GET/commission/types': (params: any, extra: Extra) => {
    return axios.get<void>(`/commission/types`)
  },
    
  /**
   * @description: 提成项目删除
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'DELETE/commission/types/del/{id}': (params: {id: number}, extra: Extra) => {
    return axios.delete<void>(`/commission/types/del/${params.id}`)
  },
    
  /**
   * @description: 提成项目修改或创建
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/commission/types/create_or_update': (params: any, extra: Extra) => {
    return axios(`/commission/types/create_or_update`, preParams(params, extra, 'post'))
  },
}